@media (max-width: 980px) {
   .banner {
       background: none;
       &__wrap {
           padding-top: 70px;
           padding-bottom: 50px;

       }
       &__info {
           margin-left: 0;
           max-width: 100%;
       }
   }
   .works {
       &__title {
           font-size: 24px;
       }
       &__name {
           font-size: 18px;
       }
   }
   .skills__left {
       margin-right: 30px;
   }
   .blog {
       &__element {
           &:nth-child(1) {
               .blog__link {
                   font-size: 20px;
               }
           }
           &:nth-child(2) {
               .blog__subtitle {
                   font-size: 20px;
               }
           }
       }
       &__link {
           font-size: 20px;
       }
   }
   .clients {
       &__carousel {
           padding-left: 50px;
           padding-right: 50px;
       }
   }
   .contacts {
       &__list {
           flex-direction: column;
       }
       &__form {
           flex-basis: auto;
           margin-right: 0;
           margin-bottom: 35px;
       }
       &__map {
           max-width: 100%;
       }
   }
}

@media (max-width: 768px) {
    .header {
        &__wrap {
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 25px;
            padding-bottom: 25px;
        }
        &__logo {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    .menu {
        &__element {
            margin-right: 20px;
        }
    }

    .banner {
        &__title {
            font-size: 50px;
        }
        &__little {
            font-size: 25px;
        }
        &__text {
            font-size: 16px;
        }
    }
    .works {
        &__list {
            grid-template-columns: repeat(2, minmax(145px, auto));
        }
        &__element {
            grid-column: span 1;
            &:first-child {
            grid-column: 1 / -1;
            max-width: 100%;
           }
        }
    }
    .responsive {
        &__wrap {
            background-image: none;
        }
        &__info {
            margin-left: 0;
            max-width: 100%;
        }
    }
    .skills {
        &__wrap {
            flex-direction: column;
        }
        &__left {
            margin-right: 0;
            margin-bottom: 25px;
            flex-basis: auto;
        }
    }
    .blog {
        &__list {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
        }
        &__element {
            grid-column: span 1;
            grid-row: span 1 !important;
        }
    }
}

@media (max-width: 480px) {
    .banner {
        &__title {
            font-size: 40px;
        }
        &__little {
            font-size: 20px;
        }
        &__text {
            font-size: 14px;
        }
        &__button {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .advantages {
        padding-top: 30px;
        padding-bottom: 30px;
        &__item {
            text-align: center;
        }
        &__img {
            margin: 0 auto;
        }
        &__subtitle {
            text-align: center;
        }
        &__text {
            text-align: center;
        }
    }
    .works {
        &__list {
            grid-template-columns: minmax(290px, auto);
            padding-top: 40px;
            padding-bottom: 89px;
        }
    }
    .responsive {
        &__wrap {
           padding-top: 50px;
           padding-bottom: 50px;
        }
        
    }
    .skills {
        &__wrap {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    .blog {
        &__list {
            grid-template-columns: minmax(290px, auto);
            padding-top: 55px;
            padding-bottom: 75px;
        }
        &__element {
           &:nth-child(2) {
               grid-row: 1 / 2 !important;
           }
        }
    }
    .clients {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 40px;
        }
    }
    .contacts {
        &__wrap {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}