.menu {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    &__element {
        margin-right: 35px;
        &:last-child {
            margin-right: 0;
        }
    }
    &__link {
        color: $text-color;
        font-family: 'Raleway';
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        display: inline-block;
        text-decoration: none;
        transition: 500ms all;
        &::before {
            content: '';
            width: 8px;
            height: 2px;
            background-color: $second;
            display: block;
            margin-bottom: 3px;
            opacity: 0;
            transition: 500ms all;
        }
        &:hover {
            color: $second;
            &::before {
                opacity: 1;
            }
        }
    }
}