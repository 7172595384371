.responsive {
    &__wrap {
        padding-top: 99px;
        padding-bottom: 100px;
        background: url('../img/bg-two.png') left bottom no-repeat;
    }
    &__info {
        max-width: 360px;
        margin-left: auto;
    }
    &__title {
        font-family: 'Raleway';
        font-size: 30px;
        font-weight: 700;
        line-height: 0.93;
        color: $white;
        text-transform: uppercase;
        position: relative;
        &::before {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $lineColor;
            position: absolute;
            left: 0;
            top: -30px;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 1.43;
        color: $respText;
    }
    &__button {
        @include button ($third);
        margin-top: 52px;

    }
}