.advantages {
    padding-top: 80px;
    padding-bottom: 80px;
    &__title {
        @include visually-hidden();
    }
    &__list {
        list-style: none;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(210px, auto));
        grid-column-gap: 38px;
    }
    &__item {
        &:hover {
            cursor: pointer;
            .advantages__img {
                border-color: $second;
            }
            .advantages__svg {
                fill: $second;
            }
            .advantages__subtitle {
                color: $second;
            }
            .advantages__text {
                color: $white;
            }
        }
    }
    &__img {
        width: 100px;
        height: 100px;
        border: solid 2px $circleBg;
        border-radius: 50%;
        background-color: rgba($color: $circleBg, $alpha: 0);
        position: relative;
        transition: all 500ms;
    }
    &__svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 40px;
        fill: $iconBg;
        transition: all 500ms;
    }
    &__subtitle {
        font-family: 'Raleway';
        font-size: 18px;
        font-weight: 800;
        text-align: left;
        color: $colorTitle;
        text-transform: uppercase;
        margin: 30px 0 18px;
        transition: all 500ms;
    }
    &__text {
        font-family: Roboto;
        font-size: 14px;
        line-height: 1.43;
        text-align: left;
        color: $colorText;
        transition: all 500ms;
        &::after {
            content: '';
            display: block;
            width: 70px;
            height: 2px;
            background-color: $second;
            margin-top: 28px;
        }
    }
}