/*
 *  Owl Carousel - Core
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden; /* fix firefox animation glitch */
		display: flex;
		align-items: center;
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-wrapper,
	.owl-item{
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}
	.owl-item {
		img {
			display: block;
			max-width: 100%;
			margin: 0 auto;
			opacity: 0.3;
			transition: all 500ms;
			&:hover {
				opacity: 1;
			}
		}
	}

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.owl-nav button.owl-prev,
	.owl-nav button.owl-next,
	button.owl-dot {
		background: none;
		color: inherit;
		border: none;
		padding:0!important;
		font: inherit;
	}

	.owl-nav button.owl-prev, .owl-nav button.owl-next {
		background: url(../img/arrow-sprite.png) left top no-repeat;
		width: 30px;
		height: 30px;
		position: absolute;
		margin: 0;
		transform: translateY(-50%);

	}

	.owl-nav button.owl-prev {
		background-position: 0 -30px;
		right: 0;
		top: 50%;
		&:hover, &:active {
			background-position: -30px -30px;
		}
	}

	.owl-nav button.owl-next {
		left: 0;
		top: 50%;
		&:hover, &:active {
			background-position: -30px 0;
		}
	}

	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		visibility: hidden;
	}

	&.owl-drag .owl-item {
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.owl-grab {
		cursor: move;
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}
