@charset "UTF-8";

/**
 * Основные стили
 **/

html {
	scroll-behavior: smooth;
	font-size: 16px;
	color: $black;
}

body {
	font-family: 'Roboto', 'Arial', sans-serif;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

.container {
	@include min-max(320px, 100%, 0);
	background-color: $primary;
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}