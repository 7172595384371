.clients {
    background-color: $bg;
    &__wrap {
        padding-top: 55px;
        padding-bottom: 80px;
    }
    &__title {
        font-family: Raleway;
        font-size: 30px;
        font-weight: 700;
        line-height: 0.93;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        margin: 25px 0;
        &::before {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $lineColor;
            display: block;
            margin: 0 auto 28px;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: $clientsText;
        max-width: 550px;
        margin: 0 auto;
    }
    &__carousel {
        margin: 55px 0;
        padding-left: 125px;
        padding-right: 125px;
        box-sizing: border-box;
    }
}