.header {
    border-bottom: 4px solid $second;
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 53px;
        padding-bottom: 62px;
    }
    &__logo {
        margin-right: 20px;
    }
    &__menu {
        margin: 0;
        padding: 0;
    }
}