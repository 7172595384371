.contacts {
    background-color: $third;
    &__wrap {
        padding-top: 57px;
        padding-bottom: 66px;
    }
    &__title {
        font-family: 'Raleway';
        font-size: 30px;
        font-weight: 700;
        line-height: 0.93;
        text-align: center;
        color: $white;
        text-transform: uppercase;
        margin-top: 25px;
        margin-bottom: 45px;
        &::before {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $white;
            display: block;
            margin: 0 auto 25px;
        }
    } 
    &__list {
        padding: 0;
        list-style: none;
        display: flex;
    }
    &__form {
        flex-basis: 535px;
        margin-right: 65px;
    }
    &__map {
        flex-grow: 1;
        max-width: 570px;
    }
}