.list {
    font-size: 14px;
    line-height: 1.29;
    color: $listColor;
    list-style: none;
    padding: 0;
    &__element {
       margin: 15px 0;
       padding-left: 50px;
       position: relative;
       &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 3px;
        background-color: $second;
        top: 50%;
        transform: translateY(-50%);
        left: 21px;
       }
    }
}