.blog {
    &__title {
        @include visually-hidden();
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(5, minmax(130px, auto));
        list-style: none;
        padding-top: 116px;
        padding-bottom: 154px;
    }
    &__element {
        position: relative;
        z-index: 2;
        transition: all 500ms;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(17, 17, 17, 0.4), $primary);
            z-index: -1;
        }
        &_three {
            grid-column: span 3;
        }
        &_four {
        grid-column: span 4;
        }
        &_five {
        grid-column: span 5;
        }
        &_eight {
        grid-column: span 8;
        }
        &:nth-child(1) {
            background: url(../img/img6.jpg) center/cover no-repeat;
            grid-row: 1 / 4;
            .blog__link {
                font-size: 30px;
            }
        }
        &:nth-child(2) {
            grid-row: 1 / 3;
            position: relative;
            margin-left: 19px;
            .blog__subtitle {
                font-size: 30px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 70px;
                    height: 2px;
                    background-color: $lineColor;;
                }
            }
            &::before {
                display: none;
            }
        }
        &:nth-child(3) {
            background: url(../img/img7.jpg) center/cover no-repeat;
            grid-row: 4 / 6;
            .blog__info {
                padding-left: 31px;
            }
        }
        &:nth-child(4) {
            background: url(../img/img8.jpg) center/cover no-repeat;
            grid-row: 4 / 6;
            .blog__mainlink {
                margin-left: 15px;
            }
            .blog__info {
                padding-left: 15px;
            }
        }
        &:nth-child(5) {
            background: url(../img/img9.jpg) center/cover no-repeat;
            grid-row: 3 / 6;
            .blog__mainlink {
                margin-left: 10px;
            }
            .blog__info {
                padding-left: 20px;
            }
        }
        &:hover, &:focus {
            .blog__info {
                border: 5px solid $second;
            }
            .blog__mainlink {
                background-color: $second;
            }
        }
    }
    &__info {
       padding: 10px 0;
       z-index: 2;
       height: 100%;
       box-sizing: border-box;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       border: 5px solid transparent;
       padding-left: 30px;
    }
    &__subtitle, &__link {
        font-family: 'Raleway';
        font-size: 24px;
        font-weight: 700;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
    }
    &__subtitle {
        margin-top: 20px;
    }
    &__text {
        font-size: 14px;
        line-height: 1.43;
        color: $blogText;
    }
    &__descr {
        font-size: 12px;
        line-height: 1.17;
        color: $descrColor;
        text-transform: uppercase;
        span {
            color: $white;
        }
        &::after {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $second;
            display: block;
            margin-top: 30px;
        }
    }
    &__mainlink {
        width: 41px;
        height: 41px;
        background-color: $black;
        display: block;
        position: relative;
        margin-top: 30px;
        outline: none;
        transition: all 500ms;
        &:focus {
            background-color: $second;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 22px;
            fill: $white;
        }
    }
}