.works {
    background-color: $bg;
    &__title-hide {
        @include visually-hidden();
    }
    &__list {
        padding-top: 79px;
        padding-bottom: 159px;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(12, minmax(60px, auto));
        grid-auto-rows: minmax(270px,auto);
    }
    &__element {
        position: relative;
        &_three {
            grid-column: span 3;
        }
        &_four {
            grid-column: span 4;
        }
        &_five {
            grid-column: span 5;
        }
        &_six {
            grid-column: span 6;
        }
        &:first-child {
            max-width: 290px;
            &::before {
                display: none;
            }
        }
        &:nth-child(2) {
            background: url(../../img/img1.jpg) center center/cover no-repeat;
        }
        &:nth-child(3) {
            background: url(../../img/img2.jpg) center center/cover no-repeat;
        }
        &:nth-child(4) {
            background: url(../../img/img3.jpg) center center/cover no-repeat;
        }
        &:nth-child(5) {
            background: url(../../img/img4.jpg) center center/cover no-repeat;
        }
        &:nth-child(6) {
            background: url(../../img/img5.jpg) center center/cover no-repeat;
        }
        &::before {
            content: '';
            background-color: rgba($color: #151515, $alpha: 0.85);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0;
            transition: all 500ms;
        }
        &:hover {
            &::before {
                z-index: 1;
                opacity: 1;
            }
            .works__info {
                border-color: $second;
                z-index: 1;
                opacity: 1;
            }
        }
        &_active {
            &::before {
                z-index: 1;
                opacity: 1;
            }
            .works__info {
                border-color: $second;
                opacity: 1;
                z-index: 1;
            }
        }
    }
    &__title {
        font-family: 'Raleway';
        font-size: 30px;
        font-weight: 700;
        line-height: 0.93;
        color: $white;
        text-transform: uppercase;
        margin: 30px 0;
        position: relative;
        &::before {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $lineColor;
            position: absolute;
            left: 0;
            top: -30px;
        }
    }
    &__text {
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 1.43;
        color: $worksText;
    }
    &__info {
        width: 100%;
        height: 100%;
        position: relative;
        border: 5px solid transparent;
        box-sizing: border-box;
        padding: 41px 53px 82px 25px;
        z-index: -1;
        opacity: 0;
        transition: all 500ms;
    }
    &__name {
        font-family: 'Raleway';
        font-size: 24px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
    }
    &__description {
        font-family: Roboto;
        font-size: 12px;
        line-height: 1.67;
        color: $descrColor;
        text-transform: uppercase;
        &::after {
            content: '';
            width: 70px;
            height: 2px;
            background-color: $second;
            display: block;
            margin-top: 28px;
        }
    }
    &__zoom, &__link {
        border-radius: 50%;
        background-color: $primary;
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 42px;
        height: 42px;
        margin: 0 2px;
        appearance: none;
        padding: 0;
        border: none;
        transition: all 500ms;
        &:hover, &:active, &:focus {
            background-color: $second;
        }
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            fill: $white;
            width: 15px;
            height: 15px;
        }
    }
    &__btns {
        position: absolute;
        right: 30px;
        bottom: 30px;
    }
}