.form {
    label {
        @include visually-hidden();
    }
    &__input {
        margin-bottom: 20px;
        input, textarea {
            width: 100%;
            display: block;
            background-color: $bg;
            border: none;
            box-shadow: none;
            resize: none;
            border-radius: 10px;
            font-family: 'Raleway';
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.4;
            text-align: left;
            padding: 16px 34px;
            box-sizing: border-box;
            &::placeholder {
                color: $white;
                opacity: 1;
            }
        }
        textarea {
            min-height: 200px;
        }
    }
    &__button {
        @include button;
        width: 100%;
        border-radius: 10px;
        height: 60px;
    }
}