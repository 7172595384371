.banner {
    background: url('../../img/bg-man.jpg') center top/cover no-repeat;
    &__wrap {
        padding-top: 266px;
        padding-bottom: 269px;
    }&__info {
        max-width: 571px;
        margin-left: auto;
    }
    &__title {
            font-family: 'Raleway';
            font-size: 80px;
            font-weight: 800;
            line-height: 1;
            text-transform: uppercase;
            color: $white;
            margin: 0;
    }
    &__little {
        display: block;
        font-size: 43px;
        span {
            color: #e5493a;
       }
    }
    &__red {
        color: $second;
    }
    &__text {
            font-family: 'Raleway';
            font-size: 18px;
            font-weight: 800;
            color: $color-text;
            text-transform: uppercase;
    }
    &__button {
        @include button() {
            display: inline-block;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-right: 25px;
        };
        &_last {
            margin-right: 0;
            border-color: $white;
            background-color: transparent;
        }
    }
}