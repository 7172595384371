.progress {
    &__name {
        font-family: 'Raleway';
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        color: $white;
        text-transform: uppercase;
        margin: 10px 0;
    }
    &__element {
        border: solid 1px rgba(255, 255, 255, 0.25);
        border-radius: 3px;
        padding: 2px;
    }
    &__line {
        width: 0;
        height: 5px;
        background-color: $white;
        position: relative;
    }
    &__tooltip {
        width: 31px;
        height: 21px;
        background-color: $primary;
        position: absolute;
        right: -15px;
        top: -33px;
        font-size: 11px;
        font-weight: 700;
        line-height: 1.82;
        text-align: center;
        color: $tooltipText;
        border-radius: 3px;
        display: none;
        &::before {
            content: '';
            border: 4px solid transparent;
            border-top: 4px solid $primary;
            position: absolute;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}